@media only screen and (max-width: 600px) {
  .activity-desktop-images {
    display: none;
  }

  .activity-line {
    color: white;
    padding-top: 2px;
    opacity: 1;
  }

  .activity-date {
    text-align: left;
    color: white;
    font-size: 13px;
  }

  .activity-title {
    color: white;
    font-size: 22px;
  }

  .activity-body {
    font-size: 12px;
    color: white;
  }

  .activity-images {
    display: none;
  }

  .activity-images img {
    width: 100%;
  }
}
