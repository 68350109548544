@media only screen and (min-width: 600px) {
  .desktop-navbar {
    position: absolute;
    width: 100%;
    padding-right: 20px;
    background-color: white;
    height: 55px;
  }

  .mobile-navbar {
    display: none;
  }

  .navbar-layout {
    display: none;
  }

  .desktop-navbar ul {
    width: 100%;
    list-style: none;
    display: flex;
    /* align-items: center; */
  }

  .logo {
    width: 128px;
  }

  .navbar-item-about-us {
    margin-right: 5%;
  }

  .navbar-button {
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 13px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    margin-left: 8%;
    background-color: white;
  }

  .navbar-volunteer-button {
    background-color: white;
    color: var(--secondary-color);
    width: 100px;
    border-radius: 4px;
    border-style: solid;
    height: 60%;
  }

  .navbar-volunteer-button:hover {
    background-color: var(--secondary-color);
    color: white;
  }

  .navbar-volunteer-button:hover p {
    color: white;
  }

  .navbar-donations-button {
    width: 110px;
    margin-right: 10px;
    color: white;
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    border-style: solid;
    border-radius: 4px;
    height: 60%;
  }

  .navbar-donations-button:hover {
    color: var(--primary-color);
    background-color: white;
  }

  .navbar-donations-button p {
    color: white;
  }

  .navbar-donations-button:hover p {
    color: var(--primary-color);
  }

  .desktop-navbar ul .navbar-text {
    flex: 1;
    color: var(--secondary-color);
  }

  .desktop-navbar ul .navbar-text:hover p {
    flex: 1;
    color: var(--primary-color);
  }
}
