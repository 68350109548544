@media only screen and (max-width: 600px) {
  .landing-background-image {
    height: 215px;
    background-image: url("../../assets/images/landing.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-color: rgba(237, 237, 237);
  }

  .landing-color-fade {
    position: absolute;
    width: 100%;
    top: 160px;
    height: 50px;
    background: rgb(255, 255, 255);
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.0035364487591911242) 0%,
      rgba(255, 255, 255, 0) 0%,
      rgba(237, 237, 237, 1) 100%
    );
    z-index: 1;
  }

  .landing-title {
    margin-top: 70px;
    color: white;
    font-size: 50px;
  }
}
