@media only screen and (min-width: 600px) {
  .goals-card {
    margin: auto;
    border-bottom: solid;
    border-left: solid;
    border-right: solid;
    background-color: white;
    background: linear-gradient(white, white) padding-box,
      linear-gradient(to bottom, rgba(125, 158, 139, 0) 0%, #7d9e8b) border-box;
    height: 100%;
    border-radius: 10px;
    border: 3.5px solid transparent;
    overflow: hidden;
  }

  .goals-card-content {
    font-size: 15px;
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 40px;
  }

  .goals-card-image {
    margin-left: -5px;
    margin-top: 10px;
    margin-bottom: 4px;
    width: 90%;
  }
}
