@media only screen and (max-width: 600px) {
  .activities-background {
    background-color: var(--primary-color);
    padding-bottom: 20%;
  }

  .activities-title {
    font-weight: bold;
    font-size: 45px;
    text-align: center;
    color: white;
  }

  .activities-body {
    color: white;
    font-size: 20px;
  }
}
