@media only screen and (min-width: 600px) {
  .landing-background-image {
    height: 750px;
    background-image: url("../../assets/images/landing.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-color: rgba(237, 237, 237);
  }

  .landing-color-fade {
    position: absolute;
    width: 100%;
    top: 552px;
    height: 200px;
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0.0035364487591911242) 0%,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );

    z-index: 1;
  }

  .landing-title {
    margin-top: 375px;
    color: white;
    font-size: 50px;
  }
}
