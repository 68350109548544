@media only screen and (max-width: 600px) {
  .about-us-title {
    margin-top: 15%;
    color: var(--primary-color);
    font-size: 30px;
    font-weight: bold;
  }

  .about-us-subtitle {
    font-size: 20px;
  }

  .about-us-button {
    font-size: 20px;
    color: white;
    width: 250px;
    height: 50px;
    font-weight: bold;
    background-color: var(--primary-color);
    border-radius: 4px;
    border-style: solid;
    border-color: var(--primary-color);
  }

  .about-us-button:hover {
    color: var(--primary-color);

    background-color: white;
  }
}
