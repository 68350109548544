@media only screen and (max-width: 600px) {
  .footer-flex-break {
    flex-basis: 100%;
    height: 0;
  }

  .footer-background {
    margin-top: 30px;
    margin-bottom: 10px;
    padding-top: 5%;
    padding-bottom: 2%;
    background-color: rgb(237, 237, 237);
  }

  .footer-button {
    width: 290px;
    height: 45px;
    background-color: var(--primary-color);
    font-size: 25px;
    color: white;
    border-radius: 16px;
    border-style: solid;
    border-color: var(--primary-color);
    margin-top: 20px;
    margin-bottom: 20%;
  }

  .footer-button:hover {
    background-color: white;

    color: var(--primary-color);
  }

  .footer-icons {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    flex-wrap: wrap;
    padding-right: 17%;
  }

  .footer-icons p {
    margin-top: auto;
    margin-bottom: auto;
    margin-right: 10px;
    margin-top: 20px;
  }

  .footer-icons img {
    margin-right: 20px;
    margin-top: 10px;
  }

  .footer-logo {
    margin-top: 10%;
    margin-bottom: 5%;
  }

  .footer-social-media {
    margin-top: 2%;
  }

  .footer-facebook-icon {
    margin-left: 20px;
    margin-right: 20px;
  }
}
