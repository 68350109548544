@media only screen and (max-width: 600px) {
  .obey-hospital-background {
    background-color: #435a88;
    padding-bottom: 5%;
  }

  .obey-hospital-logo {
    display: block;
    margin-left: auto;
    margin-right: auto;
    background-color: white;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    width: 221px;
    height: 170px;
  }

  .obey-hospital-title {
    color: white;
    font-size: 40px;
    font-weight: bold;
    text-align: center;
  }

  .obey-hospital-body {
    color: white;
    font-size: 14px;
    text-align: center;
  }

  .obey-hospital-button {
    margin-top: 20px;
    margin-bottom: 15%;
    width: 250px;
    height: 40px;
    border-style: solid;
    border-color: var(--primary-color);
    background-color: var(--primary-color);
    color: white;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    margin-left: auto;
    margin-right: auto;
    display: block;
  }

  .obey-hospital-button:hover {
    background-color: white;
    color: var(--primary-color);
  }

  .obey-hospital-images {
    margin-top: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
    grid-gap: 3px;
    padding: 0;
    margin: 0;
  }

  .obey-hospital-images img {
    width: 100%;
    height: 100%;
  }
}
