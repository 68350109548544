@media only screen and (min-width: 600px) {
  .activity-mobile-images {
    display: none;
  }
  .activity-line {
    color: white;
    padding-top: 2px;
    opacity: 1;
  }

  .activity-date {
    text-align: left;
    color: white;
    font-size: 13px;
  }

  .activity-title {
    color: white;
    font-size: 22px;
  }

  .activity-body {
    font-size: 12px;
    color: white;
  }

  .activity-images {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(255px, 1fr));
    grid-gap: 0;
    padding: 0;
    margin: 0;
  }

  .activity-images img {
    width: 100%;
  }
}
