.admin-content {
  margin-top: 6%;
  width: fit-content;
  margin-left: auto;
  margin-right: auto;
}

.admin-name {
  font-size: 20px;
}

.admin-position-content {
  display: flex;
  margin-top: -15px;
}

hr {
  height: 10px;
}

.admin-line {
  margin-top: 10px;
  margin-left: 10px;
  flex: 1;
  background-color: var(--primary-color);
  opacity: 1;
  padding-bottom: 1.5px;
  padding-top: 1.5px;
}

.admin-position {
  font-size: 15px;
}
