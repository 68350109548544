@media only screen and (max-width: 600px) {
  .social-solidarity-card {
    margin-top: 15%;
    border-bottom: solid;
    border-left: solid;
    border-right: solid;
    background: linear-gradient(
          to top,
          rgb(237, 237, 237) 0%,
          rgb(237, 237, 237)
        )
        padding-box,
      linear-gradient(to bottom, rgba(125, 158, 139, 0) 0%, #7d9e8b) border-box;
    border-radius: 10px;
    border: 3.5px solid transparent;
    height: 220px;
  }

  .social-solidarity-card-title {
    font-size: 25px;
    color: var(--primary-color);
  }

  .social-solidarity-card-body,
  .social-solidarity-card-number {
    font-size: 15px;
    text-align: right;
    padding-right: 10%;
    padding-left: 10%;
  }

  .social-solidarity-card-etc {
    position: relative;
    font-size: 12px;
    padding-right: 23%;
    padding-top: 2%;
    text-align: right;
    top: 20px;
  }

  .social-solidarity-card-contact-us-button {
    width: 150px;
    height: 40px;
    border-radius: 4px;
    border-color: var(--primary-color);
    border-style: solid;
    background-color: white;
    color: var(--primary-color);
    position: relative;
    bottom: -13px;
    font-family: Zak;
  }

  .social-solidarity-card-contact-us-button:hover {
    background-color: var(--primary-color);
    color: white;
  }

  .social-solidarity-card-volunteer-button {
    width: 150px;
    height: 40px;
    border-radius: 4px;
    border-color: var(--primary-color);
    border-style: solid;
    background-color: var(--primary-color);
    color: white;
    position: relative;
    bottom: -80px;
    font-family: Zak;
  }

  .social-solidarity-card-volunteer-button:hover {
    background-color: white;
    color: var(--primary-color);
  }

  .social-solidarity-card-button-icon {
    padding-left: 10px;
  }
}
