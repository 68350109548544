@media only screen and (max-width: 600px) {
  .social-solidarity-background {
    background-color: rgba(237, 237, 237);
    padding-top: 10%;
    padding-bottom: 20%;
  }

  .social-solidarity-title {
    font-size: 45px;
    color: var(--primary-color);
    margin-bottom: -50px;
  }

  .social-solidarity-cards {
    height: 300px;
  }
}
