@media only screen and (min-width: 600px) {
  .social-solidarity-card {
    margin-left: 10%;
    margin-right: 10%;
    border-bottom: solid;
    border-left: solid;
    border-right: solid;
    background: linear-gradient(
          to top,
          rgb(237, 237, 237) 0%,
          rgb(237, 237, 237)
        )
        padding-box,
      linear-gradient(to bottom, rgba(125, 158, 139, 0) 0%, #7d9e8b) border-box;
    border-radius: 10px;
    border: 3.5px solid transparent;
    min-height: 240px;
  }

  .social-solidarity-card-title {
    font-size: 25px;
    color: var(--primary-color);
  }

  .social-solidarity-card-body,
  .social-solidarity-card-number {
    font-size: 15px;
    text-align: right;
    padding-right: 23%;
    padding-left: 23%;
  }

  .social-solidarity-card-etc {
    position: relative;
    font-size: 12px;
    padding-right: 23%;
    padding-top: 2%;
    text-align: right;
    top: 30px;
  }

  .social-solidarity-card-contact-us-button {
    width: 150px;
    height: 40px;
    border-radius: 4px;
    border-color: var(--primary-color);
    border-style: solid;
    background-color: white;
    color: var(--primary-color);
    position: relative;
    bottom: -30px;
    font-family: Zak;
  }

  .social-solidarity-card-contact-us-button:hover {
    background-color: var(--primary-color);
    color: white;
  }

  .social-solidarity-card-volunteer-button {
    width: 150px;
    height: 40px;
    border-radius: 4px;
    border-color: var(--primary-color);
    border-style: solid;
    background-color: var(--primary-color);
    color: white;
    position: relative;
    bottom: -80px;
    font-family: Zak;
  }

  .social-solidarity-card-volunteer-button:hover {
    background-color: white;
    color: var(--primary-color);
  }

  .social-solidarity-card-button-icon {
    padding-left: 10px;
  }
}

@media only screen and (min-width: 750px) {
  .social-solidarity-card-contact-us-button {
    bottom: -22px;
  }

  .social-solidarity-card-volunteer-button {
    bottom: -80px;
  }
}

@media only screen and (min-width: 959px) {
  .social-solidarity-card-contact-us-button {
    bottom: -22px;
  }
}

@media only screen and (min-width: 975px) {
  .social-solidarity-card-contact-us-button {
    bottom: -22px;
  }

  .social-solidarity-card-volunteer-button {
    /* top: auto; */
    bottom: -80px!;
  }
}

@media only screen and (min-width: 1364px) {
  .social-solidarity-card-contact-us-button {
    bottom: -20px;
  }

  .social-solidarity-card-volunteer-button {
    bottom: -80px;
  }
}

@media only screen and (min-width: 1383px) {
  .social-solidarity-card-contact-us-button {
    bottom: -30px;
  }

  .social-solidarity-card-volunteer-button {
    bottom: -100px;
  }
}
