@media only screen and (min-width: 600px) {
  .social-solidarity-background {
    background-color: rgba(237, 237, 237);
    padding-top: 7%;
    padding-bottom: 7%;
  }

  .social-solidarity-title {
    font-size: 45px;
    color: var(--primary-color);
  }

  .social-solidarity-cards {
    height: 300px;
  }
}
