@media only screen and (min-width: 600px) {
  .goals-background {
    background-color: #ededed;
    width: 100%;
  }

  .goals-title {
    margin-top: 2%;
    font-size: 45px;
    color: var(--primary-color);
    font-weight: bold;
  }

  .goals-button {
    color: white;
    width: 280px;
    height: 50px;
    font-size: 20px;
    font-family: Zak;
    border-style: solid;
    border-color: var(--primary-color);
    background-color: var(--primary-color);
    border-radius: 4px;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  .goals-button:hover {
    color: var(--primary-color);
    background-color: white;
  }

  .goals-margin {
    margin-top: 2%;
  }
}
