@media only screen and (max-width: 600px) {
  .copyright-sa {
    margin-top: 15px;
  }

  .copyright-nexus,
  .copyright-sa {
    font-size: 12px;
    text-align: center;
  }
}
