@font-face {
  font-family: Zak;
  src: url("./assets/fonts/ArbFONTS-RH-Zak-Reg.otf") format("opentype");
}

@font-face {
  font-family: Zak;
  src: url("./assets/fonts/ArbFONTS-RH-Zak-Bold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: Zak;
  src: url("./assets/fonts/ArbFONTS-RH-Zak-Thin.otf") format("opentype");
  font-weight: 100;
}

:root {
  --primary-color: #7d9e8b;
  --secondary-color: #6f6f70;
  --bg-color: rgba(237, 237, 237);
}

body {
  background-color: white;
}

.center {
  text-align: center;
}

.left {
  text-align: left;
}

p {
  font-family: Zak;
  color: var(--secondary-color);
}
